import { render, staticRenderFns } from "./ReserveAppPreview.vue?vue&type=template&id=6844ceb4&scoped=true&"
import script from "./ReserveAppPreview.vue?vue&type=script&lang=ts&"
export * from "./ReserveAppPreview.vue?vue&type=script&lang=ts&"
import style0 from "./ReserveAppPreview.vue?vue&type=style&index=0&id=6844ceb4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6844ceb4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDivider,VIcon})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
